import React, { useEffect, useState } from "react"
//import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select"
import { userGroupDetails , getDirectoriesOptions} from "./actions";
//import getDirectoriesSaga from "../Dashboard/directoriesSaga";
import '../common/css/style.css';

const UserGroup = (props) => {
    const[ email, setEmail ] = useState("") 
    const[ allEntry, setAllEntry ] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])

    const dispatch = useDispatch();


    const [options, setOptions] = useState([])

    const handleSelectChange = (selected) =>
    {
      setSelectedOptions(selected)
    
    }
    const dirOptionsList = useSelector((state) => { 
      return state.UserGroupReducer.directoriesList
    });
    useEffect(() => {
      setOptions(dirOptionsList)
    } , [dirOptionsList]);

    //const [value, setValue] = useState(null)

    const submitForm = (e) => {
          e.preventDefault()

          const newEntry = { email:email}

          setAllEntry([...allEntry, newEntry])

          dispatch(userGroupDetails({'user_group_name' : newEntry.email, 'selected_dirs' : selectedOptions}))
    }

    useEffect(() => {
     // () => {
      //  fetch('http://localhost:5000/api/getdirectorieslist').then((response) => response.json()).then((data) => setOptions(data.options)).catch((error) => console.error('Error fetching options:', error))
      //},[]

      dispatch(getDirectoriesOptions({})) },[]);


  return (
    <div class="form-container">
      <form action="" onSubmit={submitForm}>
        <h2>Create User Group</h2>
        
            <div class="form-row">
            <label htmlFor="email">
             User group
            </label>
            <input type="text" class="email" name="email" id="email" value={email} 
            onChange={
                (e) => setEmail(e.target.value)
            } 
            />
            </div>
            
           
        
          <div class="form-row">
          <label htmlFor="options"> Select options: </label>
          <Select isMulti options={options} value={selectedOptions} onChange={handleSelectChange}>
          </Select>  
          <br />
          </div>

          <div class="form-row">
          <input type="submit" value="Submit"/>
          </div>
      
      </form>
      
      <div>
        {
            allEntry.map(
                (curElement)=>{
                    return(
                        <div className="showDetails">
                          <p> { curElement.email } </p> 
                           <p> { curElement.password } </p>
                        </div>
                    )
                }
            )
        }
      </div>

      <div>
        <h2>Selected Options:</h2>
        {selectedOptions.length >0 ? 
         (
          <ul>
            {selectedOptions.map((option) => (
              <li key={option.value}>{option.label}</li>
            ))}
          </ul>
         ) : (
          <p>No options selected</p>
         )
        }
      </div>

    </div>
  )
}

export default UserGroup

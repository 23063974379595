import React, { useEffect, useState, useCallback } from 'react';
import './modal.css';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PearsonLogo from '../../../images/pearson_logo.png';
import iconFolder from '../../../images/icon_folder.gif';
import fileIcon from '../../../images/icon_file.gif';
import '../../common/css/pw_push5.css';
import { getSubDirectories, downloadBulkFilesInZip, resetBulkUploadStateManageMent} from '../../Dashboard/actions';
import Loader from "../../common/Loader";
import { updateGenricSuccessMessageToEmpty } from '../../User/actions';

const BulkDownloadPopup = (props) => {
    const [filesLists, setFilesList] = useState([]);
    const dispatch = useDispatch();
    const location = useLocation();
    const [disableCheckbox,setDisableCheckbox] = useState(true);
    const [loading,setLoading] = useState(false);
    //const loading = useSelector(state => state.loading);
    const [deleteFilesArray, setDeleteFilesArray] = useState([]); // this variable is used for both download or deleting the files
    const [downloadLoaderVisible, setDownloadLoaderVisible] = useState(false);

    let currentURI = location.pathname;

    let currentURImatches = currentURI.match(/^\/mdc\/auth\/([^\/]+)/);
    //console.log(currentURImatches);
    let uriPrefex = currentURImatches[0];
    if(currentURImatches == null)
    {
        // Invalid location pathname
    }
    //console.log(uriPrefex,"===============",currentURI);
    let currentPath = currentURI.replace(`${uriPrefex}`,"");
    currentPath = currentPath.split('/');
    currentPath = currentPath.filter(path => path);
    currentPath = `/${currentPath.join('/')}`
    if(currentPath === "")
    {
      currentPath = '/';
    }
    const directoriesList = useSelector(state => { return state.directoriesReducer.serverResponse });
    const downloadFileLimitMsg = useSelector(state => { return state.UserReducer.userSuccessMessage} );
    const closeBulkDownloadPopup = useSelector(state => { return state.directoriesReducer.closeDownloadPopup} );

    useEffect(() => {
      if(Object.entries(downloadFileLimitMsg).length > 0){
        setDownloadLoaderVisible(false);
        props.onModalPopupStateChange(props.displayModal);
      }
      dispatch(resetBulkUploadStateManageMent({}));
    }, [downloadFileLimitMsg]);

    useEffect(() => {
      if(closeBulkDownloadPopup?.status_code == 200)
      {
        props.onModalPopupStateChange(props.displayModal);
      }
      dispatch(resetBulkUploadStateManageMent({}));
    }, [closeBulkDownloadPopup])


    useEffect(()=>{
      dispatch(updateGenricSuccessMessageToEmpty({}));
      setFilesList(directoriesList?.data);
      deSelectAllFiles();
      setLoading(false);
  },[directoriesList]);

    // when user navigates back using browser's back button, need to detect path changes and get directories
    useEffect(() =>{
      setLoading(true);
      dispatch(getSubDirectories({dir:currentPath}));

      if(currentPath !== '/')
      {
        setDisableCheckbox(false);
      }else{
        setDisableCheckbox(true);
      }
    },[currentPath, dispatch]);
  

    const deleteFiles = (e) => {
      
      if(e.target.checked)
      {
        setDeleteFilesArray([...deleteFilesArray, e.target.value])
      }else{
        setDeleteFilesArray(deleteFilesArray.filter(file => file !== e.target.value));
      }
    }

    const selectAllFiles = (e) => {
      let checkboxArray = document.getElementsByName("fileDownload[]");
      let fileArray = [];
      checkboxArray.forEach((file) => { file.checked = true; fileArray.push(file.value) });
      setDeleteFilesArray([...deleteFilesArray, ...fileArray]);
    }

    const deSelectAllFiles = (e) => {
      let checkboxArray = document.getElementsByName("fileDownload[]");
      checkboxArray.forEach((file) => { setDeleteFilesArray([]); file.checked = false; })
    }

    const closeModal = (e) => {
      e.stopPropagation();
      props.onModalPopupStateChange(props.displayModal);
    };

    /**
     * bulk download of files from selected foder
     */

     const handleDownloadSelected = async() => {
      const pathsToDownload = deleteFilesArray.map((item) => currentPath + '/' + item);
      const encodedPaths = pathsToDownload.map(path => encodeURIComponent(path));

      if (pathsToDownload.length) {
        setDownloadLoaderVisible(true);
        dispatch(downloadBulkFilesInZip({ 'encodedPaths': { 'encodedPaths': encodedPaths, 'dir': encodeURIComponent(currentPath) } }));

        // setTimeout(() => {
        //     setDownloadLoaderVisible(false);   
        //     const alertMessage = 'Please wait for a couple of seconds. Your selected files are downloading. If the download is not happening, please allow browser pop-ups for the site.';  
        //     alert(alertMessage);
        //     fetchDirectories(); 
        // }, 8000);
    } else {
        alert('Please Select files that you need to download');
    }
};

const fetchDirectories = useCallback(async () => {
    try {
        setLoading(true);
        await dispatch(getSubDirectories({ dir: currentPath }));
    } catch (error) {
        console.error('Error fetching directories:', error);
    } finally {
        setLoading(false);
    }
}, [currentPath, dispatch]);

    return (
      <>
      <div
        className="modal"
        onClick={ closeModal }>
           <div className="modal-content"
           style={{ Height: '50px'  }}
            onClick={ e => e.stopPropagation() } >

            <table border="0" width="100%" cellSpacing="3" cellPadding="0" className='modal-content-table'>
               <tr align="left" valign="top">
                   <td align="left" valign="middle"><span className="pagetitle">&nbsp;Download Files</span></td>
                   <td align="right" valign="middle"><img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="85" height="28" hspace="0" vspace="0" border="0"/></td>
                   <td><span
                           className="close" onClick={closeModal}
                           >&times;
                       </span>
                   </td></tr>
                   </table>
        <div className='generalcoursecontainer'>
        <div className="table-container-bulkdownload">
          <table className='myTable' border="1" width="99%" cellSpacing="1" cellPadding="2" >
            <tbody>
              <tr  align="left" valign="top">
                <th   align="center" valign="middle" width="60">Download</th>
                <th   align="left" valign="middle"> &nbsp;Name</th>
                <th   align="left" valign="middle" width="100">&nbsp;Size (Bytes)</th>
                <th   align="left" valign="middle" width="120">&nbsp;Last Modified</th>
              </tr>
              
              {loading ? <tr colSpan="8"><Loader/></tr>: (
              filesLists && filesLists.length > 0 && (
                filesLists.map((fileList,index) => {
                  let cellStyle = "cellGreen";
                  if(index % 2 === 0) 
                  {
                    cellStyle = "cellWhite";
                  }
                  let folderIcon = iconFolder;
                  if(fileList['isFile'])
                  {
                    folderIcon = fileIcon
                  }
                  return (<tr align="left" valign="top" key={index}>
                      <td   width="70" className={"checkboxalign " +cellStyle} >
                        <input type="checkbox" name="fileDownload[]" value={fileList['directory']} disabled={disableCheckbox} onClick = {deleteFiles}/>
                      </td>
                        <td className={cellStyle} nowrap="">
                        <div className="folderpointer" >
                          
                        &nbsp;<img alt={''} src={folderIcon} />&nbsp;{fileList['directory']}
                        </div>
                      </td>
                      <td className={cellStyle}>{fileList['size']}</td>
                      <td className={"checkboxalign " + cellStyle}>{fileList['last_modified_date']}</td>
                        </tr>)
                })))}
               
            </tbody>
           
          </table>
          </div>
          { (!disableCheckbox && localStorage.getItem('user.role') !== 'Vendor') && 
          <table width="100%">
              <tr align="left" valign="top">
                <td align="left"><button className="cellWhite formButtonGo delete-selected" onClick={selectAllFiles}>Select&nbsp;All</button>
                  <span className="cellWhite">&nbsp;|&nbsp;</span>
                  <button  className="cellWhite formButtonGo delete-selected" onClick={deSelectAllFiles}>Clear&nbsp;All</button></td>
              </tr>
              <tr align="left" valign="top">
                  <td><button className="formButtonDownload" onClick={handleDownloadSelected}>Download Selected</button></td>
              </tr>
              {downloadLoaderVisible && (
                <tr align="left" valign="top">
                  <td style={{color : 'green'}}>
                    Please wait for a couple of seconds. Your selected files are downloading. If the download is not happening, please allow browser pop-ups for the site.
                  </td>
                </tr>
              )}
              {downloadLoaderVisible && (
                  <Loader />
                )}

            </table>
          }

          <br/>
          
           </div>
        
        </div>
        </div>
    </>
  )
}

export default BulkDownloadPopup;
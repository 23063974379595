import { put, call, takeLatest } from "redux-saga/effects";
import { UNZIP_API,UNZIP_FOLDER } from "../../../../Constants";
import apiService from "../../../utils/apiService";
import _ from 'lodash';



function unZip(payload)
{
    return apiService.post(UNZIP_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const unzipFile = function* (payload) {
    let response;
    try {
        response = yield call(unZip,payload.payload);
        
        return response;
    } catch (error) {

    }
}

function* unZipSaga() {
    yield takeLatest(UNZIP_FOLDER, unzipFile)
}


export default unZipSaga;
import  { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import initialState from './appInitState';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore(
    {
        reducer : rootReducer,
        //middleware: [sagaMiddleware],
        preloadedState: initialState,
        middleware: [sagaMiddleware]
    });
sagaMiddleware.run(rootSaga);
export default store;
import { put, call, takeLatest } from "redux-saga/effects";
import { UPLOAD_PATH, UPLOAD_FILES,MEDIA_FILE_UPLOADED_SUCCESSFULLY } from "../../../Constants";
import apiService from "../../utils/apiService";
import _ from 'lodash';
import {  noDirOrUserFound } from "../../Dashboard/actions"

function uploadMediaFiles(payload)
{
    return apiService.postMedia(UPLOAD_PATH,payload.formdata,payload.options).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const fileUploader = function* (payload) {
    let response;
    try {
        response = yield call(uploadMediaFiles,payload.payload);
        if(_.get(response,"status_code",'') === 404)
        {
            yield put(noDirOrUserFound(response));
        }
        //if(_.get(response,"status",'failed') === 'success') {
            yield put({ type : MEDIA_FILE_UPLOADED_SUCCESSFULLY, response});
        //}
        

        return response;
    } catch (error) {

    }
}

function* fileUploadSaga() {
    yield takeLatest(UPLOAD_FILES, fileUploader);
}


export default fileUploadSaga;
import { SUBMIT_USER_GROUP_DETAILS, GET_DIRECTORIES_OPTIONS, PUT_DIRECTORIES_OPTIONS,
    GET_ACTIVE_DIRECTORY_USER, ACTIVE_USER_DIRECTORY_LIST, ADD_AD_USER,GET_USER_LIST, PUT_USER_LIST,
    ENABLE_DISABLE_USER, DELETE_USER, EMPTY_GENERIC_SUCCESS_MESSAGE, EDIT_USER_INFO, EDIT_USER_DETAILS,
    UPDATE_USER_DETAILS } from "../../Constants" 


export function userGroupDetails(payload)

{
    return {
        type: SUBMIT_USER_GROUP_DETAILS,
        payload:payload
    };
}

export function getDirectoriesOptions(payload)

{
    return {
        type: GET_DIRECTORIES_OPTIONS,
        payload:payload
    };
}

export function updateDirectoryOptionsList(payload)
{
    return {
        type: PUT_DIRECTORIES_OPTIONS,
        payload:payload
    };
}

export function searchUserInAD(payload)
{
    /**
     * GET ACTIVE DIRECTORY USER
     */
    return {
        type: GET_ACTIVE_DIRECTORY_USER,
        payload
    }
}

export function activeDirectoryUserList(payload)
{
    /**
     * SHOW USER DROPDOWN LIST
     */
    return {
        type: ACTIVE_USER_DIRECTORY_LIST,
        payload
    }
}

export function addADUser(payload)
{
    /**
     * active directory user payload
     */
    return {
        type : ADD_AD_USER,
        payload
    }
}

export function getUserList(payload)
{
    /**
     * get user list from document db
     */
    return {
        type : GET_USER_LIST,
        payload
    }
}

export function putUserList(payload)
{
    /**
     * get user list from document db
     */
    return {
        type : PUT_USER_LIST,
        payload
    }
}

export function disableUser(payload)
{
    /**
     * get user list from document db
     */
    return {
        type : ENABLE_DISABLE_USER,
        payload
    }
}

export function deleteUserAction(payload)
{
     /**
     * delete user from document db
     */
      return {
        type : DELETE_USER,
        payload
    }
}

export function updateGenricSuccessMessageToEmpty(payload)
{
    /**
     * update statemanagement for generic message
     */
    return {
        type : EMPTY_GENERIC_SUCCESS_MESSAGE,
        payload
    }
}

export function editUserInfoAction(payload)
{
    /**
     * EDIT USER INFO W.R.T PEROOT ID
     */
     return {
        type : EDIT_USER_INFO,
        payload
    }
}

export function editUserDetailsState(payload)
{
    /**
     * UPDATE EDIT USER STATE
     */
     return {
        type : EDIT_USER_DETAILS,
        payload
    }
}

export function updateADUser(payload)
{
    /**
     * UPDATE USER
     */
     return {
        type : UPDATE_USER_DETAILS,
        payload
    }
}


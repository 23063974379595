import React from 'react';
import Banner from '../Banner/Banner';
import Header from '../Header/Header'

class ErrorBoundaryInner extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
      this.userName = "Dev Environment - Media Delivery Console";
    }
  
    static getDerivedStateFromError(_error) {
      return { hasError: true };
    }
  
    componentDidUpdate(prevProps, _previousState) {
      if(!this.props.hasError && prevProps.hasError) {
        this.setState({ hasError: false });
      }
    }
  
    componentDidCatch(_error, _errorInfo) {
        console.log(_error);
      this.props.setHasError(true);
    }
  
    render() {
      return this.state.hasError
        ? 
        <>
        <Header title={this.userName}/>
        <body>
        <Banner  title={this.userName} currentPath = "" disableCheckbox={true} dirListCount = { 0 }/>
        <h3><center>Page is not working currently, Please check with Admin</center></h3>
        </body>
        </>
        : this.props.children; 
    }
  }

  export default ErrorBoundaryInner;
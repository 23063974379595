import { PUT_DIRECTORIES_OPTIONS, ACTIVE_USER_DIRECTORY_LIST, PUT_USER_LIST,ENABLE_DISABLE_USER,
    USER_DISPLAY_MSG, EMPTY_GENERIC_SUCCESS_MESSAGE, EDIT_USER_DETAILS } from '../../Constants';

let initialState = {
    directoriesList: [],
    userSuggestionList: [],
    userList : [],
    editUserDetails : [],
    userSuccessMessage : ''
}

const UserReducer = (state = initialState, action) => {
    let actionState = state;
    switch (action.type) {
        case PUT_DIRECTORIES_OPTIONS:
            actionState = Object.assign({...actionState, directoriesList : action.payload.data});
            return actionState;
        case ACTIVE_USER_DIRECTORY_LIST :
            if(!action.payload.inputIsEmail){
                actionState = Object.assign({...actionState, userSuggestionList : action.payload.value});
            }else{
                let userArray = [];
                userArray.push(action.payload)
                actionState = Object.assign({...actionState, userSuggestionList : userArray});
            }
            return actionState;
        case PUT_USER_LIST:
            actionState = Object.assign({...actionState, userList : action.payload.data});
            return actionState;
        
        case USER_DISPLAY_MSG:
            actionState = Object.assign({ ...actionState, userSuccessMessage : action.response });
            return actionState;
        case EMPTY_GENERIC_SUCCESS_MESSAGE:
            actionState = Object.assign({ ...actionState, userSuccessMessage : action.payload });
            return actionState;
        case EDIT_USER_DETAILS:
            actionState = Object.assign({ ...actionState, editUserDetails : action.payload.data });
            return actionState;
        
        default :
            return state;
    }
}

export { UserReducer };
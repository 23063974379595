import { ADD_SMS_INFO,DELETE_SMS_INFO } from "../../../../Constants";

export function addSmsInfo(payload){
    return{
        type:ADD_SMS_INFO,
        payload
    }
}

export function deleteSmsInfo(payload){
    return{
        type:DELETE_SMS_INFO,
        payload
    }
}
const Header = (props) =>{

    return(
        <header>
            <meta httpEquiv="Content-type" content="text/html; charset=utf-8" />
            <title>{props.title}</title>
        </header>
    );
}

export default Header;
import base from './base';
import local from './local';
import dev from './dev';
import qa from './qa';
import stg from './stg';
import prod from './prod';

const getEnv = () => {
  let env;
  switch (window.location.hostname) {
    case 'localhost':
      env = Object.assign({}, base, local);
      break;
    // Dev
    case 'mediaconsole-dev.pearson.com':
      env = Object.assign({}, base, dev);
      break;
    // QA
    case 'mediaconsole-qa.pearson.com':
      env = Object.assign({}, base, qa);
      break;
    // Staging
    case 'mediaconsole-stg.pearson.com':
      env = Object.assign({}, base, stg);
      break;
    // Prod
    case 'mediaconsole.pearson.com':
      env = Object.assign({}, base, prod);
      break;
    default:
      env = Object.assign({}, base, stg);
  }
  return env;
};

export default getEnv();
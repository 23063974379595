/*
    mode: sso | stand-alone 
*/
const configData = {
    dev: {
        mode: 'sso',
        ssoRedirect: 'https://mycloud.pearson.com',
        basePath: '',
        cookie: 'myCloudProxySession'
    },
    prod: {
        mode: 'sso',
        ssoRedirect: 'https://mycloud.pearson.com/',
        basePath: '',
        cookie: 'myCloudProxySession'
    }
}

const config = configData['dev']

export default config;

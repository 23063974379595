import { put, call, takeLatest } from "redux-saga/effects";
import {  CREATE_NEW_FOLDER, CREATE_NEW_FOLDER_API, USER_DISPLAY_MSG } from "../../../../Constants";
import apiService from "../../../utils/apiService";
import _ from 'lodash';


function addNewFolder(payload)
{
    return apiService.post(CREATE_NEW_FOLDER_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const createFolder = function* (payload) {
    let response;
    try {
        response = yield call(addNewFolder,payload.payload);

        if(_.get(response,"status_code",'') === 200 || _.get(response,"status_code",'') === 500)
        {
            yield put({ type : USER_DISPLAY_MSG, response});
        }
        
        return response;
    } catch (error) {

    }
}


function* NewFolderSaga() {
    yield takeLatest(CREATE_NEW_FOLDER, createFolder);
}


export default NewFolderSaga;
// import React from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import '../../Modal/modal.css';
import PearsonLogo from '../../../../images/pearson_logo.png';
import deleteButton from '../../../../images/delete.jpg';


const DeleteFolder = ({ showDeleteModal, setShowDeleteModal, filesToDelete, confirmDelete, currentPath }) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    // Close modal popup
    const onModalpopupClose = () => {
        if (searchParams.has('delete')) {
            searchParams.delete('delete');
            setSearchParams(searchParams);
        }
        setShowDeleteModal(false);
    };
    
    let storedDirectoriesListDelete = JSON.parse(localStorage.getItem('directoriesListDelete'));

    const results = filesToDelete.map(fileName => {
        const foundItem = storedDirectoriesListDelete.find(item => item.directory === fileName);
        return {
          fileName,
          exists: !!foundItem, 
          isFile: foundItem ? foundItem.isFile : null 
        };
      });
      
    const directory = results.filter(file => !file.isFile);
    const files = results.filter(file => file.isFile); 

    const formattedPath = currentPath.replace(/%20/g, ' ');

    return (
        <div className="modal-content-table">
            <table border="0" width="99%" cellSpacing="3" cellPadding="0">
                <tr align="left" valign="top">
                    <td align="left" valign="middle">
                        <span className="pagetitle">&nbsp;Delete Folders/Files</span>
                    </td>
                    <td align="right" valign="middle">
                        <img src={PearsonLogo} title="Pearson Education" alt="Pearson Education" width="85" height="28" hspace="0" vspace="0" border="0" />
                    </td>
                    <td>
                        <span className="close" onClick={onModalpopupClose}>&times;</span>
                    </td>
                </tr>
            </table>
            <div id="background" className='create-new-folder'></div>
            <div id="table" className='unzip-table' >
                <table border="0" width="99%" cellSpacing="0" cellPadding="0">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" width="90" className='delete-folder-color'>
                            <img src={deleteButton} alt="New Folder" width="60" height="56" border="0" className='delete-icon' />
                        </td>
                        <td align="center" valign="middle" className="instructions">
                            <span
                                style={{
                                    paddingLeft: "9px",
                                    width: "100%",
                                    display: "block",
                                    textAlign: "center",  
                                    wordWrap: "break-word", 
                                    boxSizing: "border-box"
                                }}>
                                Are you sure you want to delete the following files/folders from the directory:
                            </span>
                           
                            <strong style={{ color: 'red' }}>{formattedPath}</strong>
                            <br />
                            <div style={{ display: "flex", justifyContent: directory.length > 0 && files.length > 0 ? 'space-between' : 'center' }}>
                                {directory.length > 0 && (
                                    <div style={{ marginLeft: directory.length > 0 && files.length === 0 ? "0" : "50px", marginRight: directory.length > 0 && files.length === 0 ? "10px" : "70px", textAlign: 'left', textJustify: 'justify' }}>
                                        <h3 style={{ marginLeft: "35px" }}>Directory:</h3>
                                        <ul>
                                            {directory.map((directory, index) => (
                                                <li key={index} style={{ fontSize: '12px', textAlign: 'left' }}>
                                                    <strong>{index + 1}. {directory.fileName}</strong>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                {files.length > 0 && (
                                    <div style={{ marginRight: files.length > 0 && directory.length === 0 ? "0" : "0", marginLeft: files.length > 0 && directory.length === 0 ? "0" : "10px", textAlign: 'left', textJustify: 'justify' }}>
                                        <h3 style={{ marginLeft: "25px" }}>Files:</h3>
                                        <ul>
                                            {files.map((file, index) => (
                                                <li key={index} style={{ fontSize: '12px', textAlign: 'left' }}>
                                                    <span style={{ fontWeight: 'bold' }}>{index + 1}. {file.fileName}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <br />
                            <br />
                            <table width="50%" border="0" cellSpacing="0" cellPadding="0" align="center">
                                <tr align="center">
                                    <td align="center" valign="middle">
                                        <input type="button" className="unzip-formButton" value="&nbsp;Cancel&nbsp;" onClick={onModalpopupClose} />
                                    </td>
                                    <td align="center" valign="middle">
                                        <input type="button" className="unzip-formButtonGo" value="&nbsp;&nbsp;Delete&nbsp;&nbsp;" onClick={confirmDelete} />
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
};

export default DeleteFolder;

import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from "react-select";
import { updateADUser, getDirectoriesOptions, getUserList } from './actions';
import "../common/Modal/modal.css"
import CircularProgress from '@mui/material/CircularProgress';


const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        //borderBottom: '1px dotted pink',
        //color: state.isSelected ? 'red' : 'blue',
        padding: 5,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 600,
        fontSize: 10,
        borderBottom: 'groove'
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const EditUser = ({ isOpen,onRequestClose }) => {

    const dispatch = useDispatch();
    const [roleOptions, setRoleOptions] = useState(['Super Admin', 'Admin' , 'Production', 'Vendor']);
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFileds] = useState({
        peroot : '',
        group : '',
        role : '',
        firstname:'',
        lastname:'',
        id:'',
        email:''
    });
    const [directoryOptions, setDirectoryOptions] = useState([]);
    const [selectedDirs, setSelectedDirs] = useState([]);
    const dirOptionsList = useSelector((state) => { 
        return state.UserReducer.directoriesList
      });
    const editUserDetailsReducer = useSelector((state) => { return state.UserReducer.editUserDetails })
    useEffect(() => {
        if(Object.keys(editUserDetailsReducer).length > 0)
        {  
            let userAccessDirsArray = [];
            let dirGrp = editUserDetailsReducer.group
            let userAccessDirs = dirGrp.split(',');
            for(let i = 0; i < userAccessDirs.length; i++)
            {
                userAccessDirsArray.push({value: userAccessDirs[i], label: userAccessDirs[i]})
            }
            setFormFileds({ 
                peroot: editUserDetailsReducer.peroot, 
                email : editUserDetailsReducer.userPrincipalName,
                firstname : editUserDetailsReducer.firstName,
                lastname : editUserDetailsReducer.lastName,
                group : editUserDetailsReducer.group,
                role : editUserDetailsReducer.role
            })
            setSelectedDirs(userAccessDirsArray);
        }
        setIsLoading(false);
      }, [editUserDetailsReducer])
    
    const resetSearch = () => {
        setFormFileds({
            peroot : '',
            group : '',
            role : '',
            firstname:'',
            lastname:'',
            id:'',
            email:''
        });
    }

    useEffect(() => {
        setIsLoading(true);
        if(localStorage.getItem('user.role') == 'Admin')
        {
            setRoleOptions(['Production', 'Vendor'])
        }
        dispatch(getDirectoriesOptions());
    }, []);

    
    useEffect(() => {

        if(dirOptionsList.length > 0)
        {
             let dirsArray = [];
             dirsArray.push({ value: 'All', label: 'All'});
             for(let i = 0; i < dirOptionsList.length; i++)
             {
                 let listDirs = {};
                 listDirs.value = dirOptionsList[i];
                 listDirs.label = dirOptionsList[i];
                 //listDirs.name = 'group';
                 dirsArray.push(listDirs);
             }
             setDirectoryOptions(dirsArray)
        }
     } , [dirOptionsList]);

     const handleChange = (data) => {
        let selectedDirList = [];
        let allSelected = data.filter((item) => {
            return item.value == 'All'
        });

        if(allSelected.length > 0 )
        {
            let userAccessDirsArray = [];
            for(let i = 0; i < dirOptionsList.length; i++)
            {
                userAccessDirsArray.push({value: dirOptionsList[i], label: dirOptionsList[i]});
                selectedDirList.push(dirOptionsList[i]);
            }
            setSelectedDirs(userAccessDirsArray);
        }else{
            for(let i = 0; i < data.length; i++)
            {
                selectedDirList.push(data[i].value);
            }
            setSelectedDirs(data);
        }
        selectedDirList = selectedDirList.toString();
        setFormFileds({...formFields,  'group' : selectedDirList })
    }

    const handleRoleChange = (e) => {
        setFormFileds({...formFields,  [e.target.name] : e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // add active directory user
        delete formFields.firstname;
        delete formFields.lastname;
        delete formFields.email;
        dispatch(updateADUser({data : formFields }));
        onRequestClose(isOpen);
        resetSearch();
        scrollToTop();
        setTimeout(() =>{
            dispatch(getUserList());
        },2000)
    }

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };

    return(
        <div >     
                          
            <div >
                {isLoading == true ? <CircularProgress color="inherit" style={{ 'position' : 'absolute', 'top': '50%', 'left': '50%', 'color':'rgb(6, 126, 167)'}}/> : <div></div>}
                <h4 className='createusermodalheading' >Edit User</h4>
                <form onSubmit={handleSubmit} style={{padding:'8px',fontSize: '8px',}}>
                    <div >
                        <label htmlFor='firstName' className='labelFontStyle'>First*</label>
                        <input type="text" name="firstName"  readOnly
                            className='emailfield labelFontStyle' value={formFields?.firstname}
                        />
                    </div>
                    <br/>

                    <div >
                        <label htmlFor='lastName' className='labelFontStyle'>Last*</label>
                        <input type="text" name="lastName"  readOnly
                            className='emailfield labelFontStyle' value={formFields?.lastname}
                        />
                    </div>
                    <br/>

                    <div >
                        <label htmlFor='emailId' className='labelFontStyle'>Email*</label>
                        <input type="text" name="emailId" readOnly
                            className='emailfield labelFontStyle' value={formFields?.email}
                        />
                    </div>
                    <br/>

                    <div>
                        <ReactSelect 
                            styles={selectStyles}
                            options={directoryOptions}
                            placeholder="Search by Folder Name*"
                            isSearchable={true}
                            className='labelFontStyle'
                            maxMenuHeight={80}
                            onChange={handleChange}
                            isMulti
                            value={selectedDirs}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            
                        />
                    </div>
                    <br/>

                    <div>
                        <select id="userRole" required className='userselectdropdown labelFontStyle' name="role" onChange={handleRoleChange}>
                            <option value='' >Select User Role*</option>
                            {
                                roleOptions.map((role) => (
                                <option key={role} value={role} selected = { role === formFields.role ? 'selected' : ''}>
                                    {role}
                                </option>
                                )
                                )
                            }
                        </select>
                    </div>
                    <br/>

                    <div style={{ float: 'right', bottom: '10px', right: '10px' }}>
                        <button onClick={onRequestClose} className = "usercreatebutton usercancel"
                        >CANCEL</button>
                        <button className = "usercreatebutton useradd" type="submit"
                        >SAVE</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default EditUser;
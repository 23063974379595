import { PUT_LOGIN_REQUEST } from '../../Constants';

let initialState = {
    serverResponse: null,
    serverResponseError: null
}

const loginReducer = (state = initialState, action) => {
    let actionState = state;
    switch (action.type) {
        case PUT_LOGIN_REQUEST:
            //actionState.serverResponse = action.response.data;
            actionState = Object.assign({...actionState, serverResponse : action.response});
            return actionState;
        default :
            return state;
    }
}

export {loginReducer};
import { put, call, takeLatest } from "redux-saga/effects";
import {  GET_DIRECTORIES_OPTIONS, GET_ACTIVE_DIRECTORY_USER, GET_DIR_OPTIONS_LIST, 
    ADD_AD_USER,ADD_USER_API, GET_USER_LIST, GET_USER_LIST_API, ENABLE_DISABLE_USER, 
    ENABLE_DISABLE_USER_API, USER_DISPLAY_MSG, DELETE_USER_API, DELETE_USER,GET_ACTIVEDIRECTORIES_USERS,
    EDIT_USER_API, EDIT_USER_INFO, UPDATE_USER_DETAILS, UPDATE_USER_API} from "../../Constants";
import apiService from "../utils/apiService";
import _ from 'lodash';
import { updateDirectoryOptionsList, activeDirectoryUserList, putUserList, getUserList,
    editUserDetailsState } from "./actions";


function getDirectoriesOptionsList(payload)
{
    return apiService.get(GET_DIR_OPTIONS_LIST,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const getDirectoriesOptions = function* (payload) {
    let response;
    try {
        response = yield call(getDirectoriesOptionsList,payload.payload);
        yield put(updateDirectoryOptionsList(response));
        return response;
    } catch (error) {

    }
}

const getActiveDirectoryUser = function* (payload)
{
    let response;
    try{
        let regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let inputIsEmail = regx.test(payload.payload.userPeroot);
        payload.inputIsEmail = inputIsEmail;
        response = yield call(getADUser,payload);
        response.inputIsEmail = inputIsEmail
        yield put(activeDirectoryUserList(response));
        return response;
    } catch (error) {

    }
}

function getADUser(payload)
{
    let userPeroot = payload.payload.userPeroot;
    let isEmail = payload.inputIsEmail;
    return apiService.get(`${GET_ACTIVEDIRECTORIES_USERS}?userPeroot=${userPeroot}&isEmail=${isEmail}`).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return {
            message : {
                info: "Un authorized"
            },
            success: false
        }
    })
}

const addActiveDirectoryUser = function* (payload)
{
    let response;
    try{
        
        response = yield call(addUser,payload);
        if(_.get(response,"status_code",'') === 200)
        {
            yield put({ type : USER_DISPLAY_MSG, response});
        }
        return response;
    } catch (error) {

    }
}

function addUser(payload)
{
    return apiService.post(ADD_USER_API,payload.payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const getUsers = function* (payload)
{
    let response;
    try{
        
        response = yield call(listUsers,payload);
        yield put(putUserList(response));
        return response;
    } catch (error) {

    }
}

function listUsers(payload)
{
    return apiService.get(GET_USER_LIST_API,payload.payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const updateUserStatus = function* (payload)
{
    let response;
    try{
        
        response = yield call(updateUserStatusInfo,payload);
        yield put(getUserList(response));
        return response;
    } catch (error) {
        
    }
}

function updateUserStatusInfo(payload)
{
    return apiService.post(ENABLE_DISABLE_USER_API,payload.payload).then((response) => {
        
        return _.get(response,"data",{});
        
    }).catch(error => {
        return error.response.data;
    })
}

const deleteUserEffect = function* (payload)
{
    let response;
    try{
        
        response = yield call(deleteRegisteredUser,payload);
        //yield put(getUserList(response));
        if(_.get(response,"status_code",'') === 200 || _.get(response,"status_code",'') === 500)
        {
            yield put({ type : USER_DISPLAY_MSG, response});
        }
        return response;
    } catch (error) {
        
    }
}

function deleteRegisteredUser(payload)
{
    return apiService.get(`${DELETE_USER_API}?peroot=${payload.payload.peroot}`).then((response) => {
        
        return _.get(response,"data",{});
        
    }).catch(error => {
        return error.response.data;
    })
}

const editUserEffect = function* (payload)
{
    let response;
    try{
        
        response = yield call(editUserDetails,payload);
        //yield put(getUserList(response));
        if(_.get(response,"status_code",'') === 200 || _.get(response,"status_code",'') === 500)
        {
            //yield put({ type : EDIT_USER_DETAILS, response});
            yield put(editUserDetailsState(response));
        }
        return response;
    } catch (error) {
        
    }
}

function editUserDetails(payload)
{
    return apiService.get(`${EDIT_USER_API}?peroot=${payload.payload.peroot}`).then((response) => {   
        return _.get(response,"data",{});
        
    }).catch(error => {
        return error.response.data;
    })
}

const updateUserDetailsEffect = function* (payload)
{
    let response;
    try{
        response = yield call(updateUserDetails,payload);

        yield put({ type : USER_DISPLAY_MSG, response});
        return response;
    } catch (error) {
        
    }
}

function updateUserDetails(payload)
{
    return apiService.post(`${UPDATE_USER_API}`,payload.payload).then((response) => {
        
        return _.get(response,"data",{});
        
    }).catch(error => {
        return error.response.data;
    })
}


function* userSaga() {
    yield takeLatest(GET_ACTIVE_DIRECTORY_USER, getActiveDirectoryUser);
    yield takeLatest(GET_DIRECTORIES_OPTIONS, getDirectoriesOptions);
    yield takeLatest(ADD_AD_USER, addActiveDirectoryUser);
    yield takeLatest(GET_USER_LIST, getUsers);
    yield takeLatest(ENABLE_DISABLE_USER, updateUserStatus);
    yield takeLatest(DELETE_USER, deleteUserEffect);
    yield takeLatest(EDIT_USER_INFO, editUserEffect);
    yield takeLatest(UPDATE_USER_DETAILS, updateUserDetailsEffect);
}


export default userSaga;



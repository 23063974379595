import { GET_SUB_DIRECTORIES,PUT_SUB_DIRECTORIES_REQUEST, PUSH_MEDIA_FILE_ACTION, EMPTY_MEDIA_SERVER_URLS,
    GET_FILE_SMS_INFO, EMPTY_SMS_STATEMANAGEMENT_INFO, NO_DIR_USER_FOUND, DELETE_SELECTED_FILES_LIST, 
    PUT_FILE_DELETE_INFO, BULK_DOWNLOAD_FILES, EMPTY_BULK_DOWNLOAD_POPUP_STATEMANAGEMENT_INFO } from "../../Constants";

export function getSubDirectories(payload)
{
    return {
        type: GET_SUB_DIRECTORIES,
        payload:payload
    };
}

export function updateSubDirectories(payload)
{
    return {
        type: PUT_SUB_DIRECTORIES_REQUEST,
        payload:payload
    }
}

export function pushfile(payload)
{
    return {
        type: PUSH_MEDIA_FILE_ACTION,
        payload:payload
    }
}

export function updateMediaServerUrlsToempty(payload)
{
    return {
        type: EMPTY_MEDIA_SERVER_URLS,
        payload
    }
}

export function getFileSMSInfo(payload)
{
    return {
        type : GET_FILE_SMS_INFO,
        payload
    }
}

export function emptySMSInfo(payload)
{
    return {
        type : EMPTY_SMS_STATEMANAGEMENT_INFO,
        payload
    }
}

export function noDirOrUserFound(payload)
{
    return {
        type : NO_DIR_USER_FOUND,
        payload
    }
}

export function deleteFilesAction(payload)
{
    return {
        type : DELETE_SELECTED_FILES_LIST,
        payload
    }
}

export function deleteFileStateInfo(payload)
{
    return {
        type : PUT_FILE_DELETE_INFO,
        payload
    }
}

export function downloadBulkFilesInZip(payload)
{
    return {
        type : BULK_DOWNLOAD_FILES,
        payload
    }
}

export function resetBulkUploadStateManageMent(payload)
{
    return {
        type : EMPTY_BULK_DOWNLOAD_POPUP_STATEMANAGEMENT_INFO,
        payload
    }
}
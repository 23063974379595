import { MEDIA_FILE_UPLOADED_SUCCESSFULLY,RESET_MEDIA_SUCCESS_MSG_STATE } from '../../../Constants';

let initialState = {
    message : ""
}

const fileUploadReducer = (state = initialState, action) => {
    let actionState = state;
    switch (action.type) {
        case MEDIA_FILE_UPLOADED_SUCCESSFULLY:
            actionState = Object.assign({...actionState, message : action.response});
            return actionState;
        case RESET_MEDIA_SUCCESS_MSG_STATE:
            actionState = Object.assign({...actionState, message : action.payload.message});
            return actionState;
        default :
            return state;
    }
}

export {fileUploadReducer};
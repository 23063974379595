import { UPLOAD_FILES,RESET_MEDIA_SUCCESS_MSG_STATE } from "../../../Constants";

export function uploadmediafiles(payload)
{
    return {
        type: UPLOAD_FILES,
        payload:payload
    };
}

export function resetAppState(payload)
{
    return {
        type: RESET_MEDIA_SUCCESS_MSG_STATE,
        payload:payload
    }
}
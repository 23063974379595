const Footer = () =>{

    return(
    <div>
        <table width="100%">
            <tr>
            <td align="left" className="copyright">Copyright &copy; 2005 Central Media Group, Pearson Higher Education</td>
            </tr>
        </table>
    </div>
    );
}

export default Footer;
import { PUT_SUB_DIRECTORIES_REQUEST, PUT_SUCCESS_LIVE_URLS, EMPTY_MEDIA_SERVER_URLS,
    PUT_SMS_FILE_INFO, EMPTY_SMS_STATEMANAGEMENT_INFO, NO_DIR_USER_FOUND, PUT_FILE_DELETE_INFO,
    CLOSE_BULK_DOWNLOAD_POPUP, EMPTY_BULK_DOWNLOAD_POPUP_STATEMANAGEMENT_INFO } from '../../Constants';

let initialState = {
    serverResponse: null,
    mediaServerURLs: null,
    smsInfo: null,
    noUserDirInfo : null,
    fileDeleteInfo : null,
    closeDownloadPopup : null
}

const directoriesReducer = (state = initialState, action) => {
    let actionState = state;
    switch (action.type) {
        case PUT_SUB_DIRECTORIES_REQUEST:
            actionState = Object.assign({...actionState, serverResponse : action.payload});
            return actionState;
        case PUT_SUCCESS_LIVE_URLS :
            actionState = Object.assign({...actionState,mediaServerURLs : action.response.data});
            return actionState;
        case EMPTY_MEDIA_SERVER_URLS :
            actionState = Object.assign({...actionState,mediaServerURLs : action.payload});
            return actionState;
        case PUT_SMS_FILE_INFO :
            actionState = Object.assign({...actionState,smsInfo : action.response.data});
            return actionState;
        case EMPTY_SMS_STATEMANAGEMENT_INFO :
            actionState = Object.assign({...actionState,smsInfo : action.payload});
            return actionState;
        case NO_DIR_USER_FOUND :
            actionState = Object.assign({...actionState,noUserDirInfo : action.payload});
            return actionState;
        case PUT_FILE_DELETE_INFO :
            actionState = Object.assign({...actionState,fileDeleteInfo : action.payload});
            return actionState;
        case CLOSE_BULK_DOWNLOAD_POPUP :
            actionState = Object.assign({...actionState,closeDownloadPopup : action.response});
            return actionState;
        case EMPTY_BULK_DOWNLOAD_POPUP_STATEMANAGEMENT_INFO :
            actionState = Object.assign({...actionState,closeDownloadPopup : null});
            return actionState;
        default :
            return state;
    }
}

export {directoriesReducer};
import { all } from "redux-saga/effects";
import loginSaga  from "../src/Components/Login/loginSaga";
import getDirectoriesSaga  from "../src/Components/Dashboard/directoriesSaga";
import fileUploadSaga from "../src/Components/common/Modal/fileUploadSaga";
import smsConfigSaga from "./Components/common/Modal/SMS/smsConfigSaga";
import unZipSaga from "./Components/common/Modal/Unzip/unZipSaga";
import userSaga from './Components/User/userSaga';
import NewFolderSaga from "./Components/common/Modal/NewFolder/NewFolderSaga";
import RenameFolderSaga from "./Components/common/Modal/RenameFolder/RenameFolderSaga";

export default function* rootSaga(){
    yield all([loginSaga(),getDirectoriesSaga(),fileUploadSaga(),smsConfigSaga(), userSaga(),unZipSaga(),
        NewFolderSaga(), RenameFolderSaga()]);
}
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {  useLocation } from 'react-router-dom';
import NamingLogo from '../../images/naming.gif';
import BackAndHomeButton from "../../images/back_home_button.png";
import HelpBreadCrumb from "../../images/help_breadcrumb.gif";
import ButtonNewFolder from "../../images/button_new_folder.gif";
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import TopNav from '../common/Banner/TopNav';
import CreateNewFolderPopUp from '../../images/create_new_folder_popup.png';
import UploadDownloadButton from '../../images/upload_download_button.png';
import BulkFileUploader from '../../images/bulk_file_uploader.png';
import BulkFileUploadInprogress from '../../images/bulk_file_upload_inprogress.png';
import FileUploadClose from '../../images/file_upload_close.png';
import DownloadFilesPopup from '../../images/download_files_popup.png';
import UnzipFile from '../../images/unzip_file.png';
import UnZipFilePopup from '../../images/unzip_file_popup.png';
import SmsProtection from '../../images/sms_protection.png';
import SmsProtectionSaveChanges from '../../images/sms_protection_save_changes.png';
import SmsProtectionLock from '../../images/sms_protection_lock.png';
import PushLive from '../../images/push_live.png';
import PushLivePopUp from '../../images/push_live_popup.png';
import PushLiveURL from '../../images/push_live_url.png';
import ListOfFiles from '../../images/list_of_files.png';
import ViewingFiles from '../../images/viewing_files.png';
import RenameFile from '../../images/rename_file.png';
import RenameFilePopUp from '../../images/rename_file_popup.png';
import DeleteFile from '../../images/delete_file.png';
import DeleteFilePopUp from '../../images/delete_file_popup.png';






const MyHelp = ({}) => {
    const userName = 'Mediadev.pearson.com - Help';
    const location = useLocation();
    const currentPath = location.pathname;


    return (
      <>
        <Header title={userName}/>
        <body>
        <TopNav  title={userName} currentPath={currentPath} />

        <span className="spacer"><br/><br/></span>

            <table border="0" width="90%" cellSpacing="1" cellPadding="2" className="myTable" align="center">
            <tr align="right" valign="top">
                <td className="helpGreen" align="right">
                
                <table border="0" width="65%" cellSpacing="0" cellPadding="5" align="right">
                    <tr align="left" valign="top">
                        <td align="left" valign="top" colspan="3"><font size="+1" color="#333333">Using the MediaServer Content Propagation Utility</font></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top" rowspan="5">&nbsp;</td>
                        <td align="left" valign="top"><HashLink to="#overview">MediaServer Overview</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#sms">Applying SMS Protection</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#filenamingpolicy">File Naming Policy</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#pushing">Pushing Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#navigation">Navigating the MediaServer</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#viewing">Viewing Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#folders">Creating a New Folder</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#renaming">Renaming Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top"><HashLink to="#uploading">Uploading / Downloading Files</HashLink></td>
                        <td align="left" valign="top"><HashLink to="#deleting">Deleting Files</HashLink></td>
                    </tr>
                    <tr align="left" valign="top">
                        <td align="left" valign="top" rowspan="5">&nbsp;</td>
                        <td align="left" valign="top"><HashLink to="#unzipping">Unzipping Files</HashLink></td>
                    </tr>
                </table>
                
                
                </td>
            </tr>
        </table>

        <span className="spacer"><br/><br/><br/></span>

        <section id="overview">
            <table border="0" width="90%" cellSpacing="1" cellPadding="2" align="center" summary="MediaServer Overview">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">MediaServer Overview</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent">
                        <p>The Pearson Media Server is a hosting facility specifically designed to house media and content files to be used in your online courses allowing users the ability to upload, preview and deliver content efficiently and reliably. Users can upload their content through to the staging area, manage their content through the Content Propagation Utility and push it live. Currently about 200 million assets totaling about 30 TB content are stored and delivered through the Media Server with a peak usage of 500+ million hits during a busy back to school month of September.</p>
                        <p>
                        The Media Server consists of two servers: a development server and a live server.
                        </p>
                        <p> <strong>Development:</strong>  <a href='https://mediaconsole.pearson.com/mdc/auth/media//' target='_blank'>https://mediaconsole.pearson.com/mdc/auth/media//</a><br/>

                        <strong>Production:</strong> https://media.pearsoncmg.com</p>

                        <p>Files are uploaded to the development server, and then "pushed" to the live server. You may only link to files on the live server. </p>

                         <p>EXE files are permitted, but may not be executable and must be zipped.</p>

                        <p>
                        Scripting languages for web development and Server side Includes will not work in Media Server and are not allowed. 
                        </p>
                        
                       </td>
                </tr>
                
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="filenamingpolicy">
            <table border="0" width="90%" cellSpacing="1" cellPadding="2" align="center" summary="File Naming Policy">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">File Naming Policy</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent">
                        <p>
                        The File Naming Policy disallows the use of spaces as a delimiter in file and folder naming conventions.
                        </p>
                        
                        <p>
                        The File Naming Policy limits characters in file names by listing 5 acceptable types of characters. All other characters are banned. Specifically allowed are the following:
                        </p>
                        <ul>
                            <li>&nbsp;  &#8226; alphanumeric characters (a-z, 0-9)</li>
                            <li>&nbsp;  &#8226; underscores</li>
                            <li>&nbsp;  &#8226; hyphens</li>
                            <li>&nbsp;  &#8226; ~ (tilde)</li>
                            <li>&nbsp;  &#8226; a single period/full point before the file extension only</li>
                        </ul>
                        <p>
                        <strong> Delimiter Recommendation - </strong>Delimiters represent one of various means to specify boundaries in a data stream. The File Naming Policy recommends using the following to delimit file and folder names:
                        </p>
                        <ul>
                            <li>&nbsp;  &#8226; Underscores. Example: file_naming_policy.doc</li>
                        </ul>
                        <p>
                        <strong> Upper/Lowercase Recommendation - </strong>Some systems treat capital and lowercase letters differently:
                        </p>
                        <ul>
                            <li>&nbsp;  &#8226; Most systems treat case the same, such that Pearson.jpg overwrites pearson.jpg</li>
                            <li>&nbsp;  &#8226; A few systems allow characters of different cases to coexist, such that Pearson.jpg and pearson.jpg are two different files.</li>

                        </ul>
                        <p>If you are creating a new course directory, each course on the Media Server must be titled with a course name, following the filename convention below, and no spaces, 8-bit characters are allowed.<br/>
                        <center><img src={NamingLogo} alt="Naming Convention" width="537" height="71" hspace="0" vspace="0" border="0" />
                        <p>Example: hss_smith_accounting_10e <br/>Example: hss_myanthrolibrary</p></center>
                        </p>
                       </td>
                </tr>
                
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="navigation">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Navigating the MediaServer">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Navigating the MediaServer</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>After you have successfully logged onto the Media Server, you will be situated at the top level of your imprint. If you will be uploading files to an existing folder, navigate to that folder by clicking the folder name.</p>
            <p>Clicking the <strong>back button </strong>will bring you up one level in the directory structure and clicking the <strong>home button</strong> will bring you to the top level of your imprint.</p>
            <center><img src={BackAndHomeButton} alt="back button" width="150" height="60" hspace="0" vspace="0" border="0" align="absmiddle"/></center> 
            <p>You can also navigate by clicking the folder names in the  <strong>Current Location</strong> bread crumb trail:</p>
            <center><img src={HelpBreadCrumb} alt="breadcrumb trail" width="321" height="43" hspace="0" vspace="10" border="0" /></center></td>
                </tr>
            </table>
        </section>


        <span class="spacer"><br/><br/><br/></span>

        <section id="folders">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Creating a New Folder">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Creating a New Folder</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent">
                        <p>To create a new folder on the Media Server, simply click the  <strong>new folder</strong> button located in the upper left-hand of your screen.</p>
                        <center><img src={ButtonNewFolder} alt="new folder" width="54" height="48" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                    <p> A new window will launch, prompting you to enter a name for the folder in the text field. Click the <strong>Create Folder</strong> button and your main browser window will refresh, displaying the new folder you have just created.</p>
                    <center><img src={CreateNewFolderPopUp} alt="new folder" width="700 " height="240" hspace="0" vspace="0" border="0" align="absmiddle" /></center></td>

                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="uploading">

            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Uploading Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Uploading / Downloading Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent">
                    <center><img src={UploadDownloadButton} alt="upload download button" width="120" height="60" hspace="0" vspace="0" border="0" align="absmiddle"/></center> 
                    <p>The Media Server allows users to upload as many files in one pass of any size.  To begin uploading files, navigate to the directory where you would like to begin posting files. Next, click the <strong>upload</strong> button located in the upper left-hand corner.</p>
                    <p>The <strong>Bulk File Uploader</strong> window will open. Drag and drop files or click to select files. Once files are selected, click the <strong>“Upload Files”</strong> button to begin the upload process. </p>
                    <center><img src={BulkFileUploader} alt="Bulk File Uploader" width="700 " height="300" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                    <p>A progress bar will appear in the bottom frame of the <strong>File Uploader</strong> window indicating the status of your upload. </p>
                    <center><img src={BulkFileUploadInprogress} alt="Bulk File Upload Inprogress" width="700 " height="470" hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                    <p>When the upload has completed, click <strong>"Close"</strong> to return to your main browser window.</p>
                    <center><img src={FileUploadClose} alt="Bulk File Upload Close" width="700" height="250" hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                    <p>To download files, click on the download button next to the upload button.  You have the option select files and folders. Simply click on the select button on the left side to begin downloading., and the files will be zipped once downloaded. </p>
                    <center><img src={DownloadFilesPopup} alt="Download Files PopUp" width="700 " height="550" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                    </td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="unzipping">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Unzipping Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Unzipping Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>If you choose to upload a ZIP file containing multiple files and folders, you may do so in the File Uploader window. It may be more beneficial to ZIP your entire directory or group of directories and files and upload the single ZIP file. After you have uploaded your ZIP file, an unzip icon will appear under the unzip column to the right of your file name. </p>
                    <center><img src={UnzipFile} alt="UnZip File" width="700 " height="50" hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                        <p>Clicking the unzip icon will prompt the following message.</p>
                        <center><img src={UnZipFilePopup} alt="UnZip File Popup" width="700 " height="200" hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                       <p>Click <strong>"Unzip"</strong> to proceed. Depending on the size and complexity of the ZIP file, the unzipping process could take a few minutes to complete. Once the unzipping process is complete and you have check to see that all of your files have been properly unloaded, you may delete the original ZIP file (see <strong>Deleting Files</strong> below).</p>
                    </td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="sms">

            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Applying SMS Protection">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Applying SMS Protection</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>You may apply SMS protection to any file or folder on the Media Server. To do so, click the <strong>open lock</strong> icon to the left of the file/folder name. </p>
                        <center><img src={SmsProtection} alt="Sms Protection" width="500 " height="40" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                        <p>This will launch the SMS Configuration window below. You have the option to enable or disable protection.  Complete the SMS configuration by entering the required information and click to <strong>“Save Changes”</strong> to close the window. </p>
                        <center><img src={SmsProtectionSaveChanges} alt="Sms Protection Save Changes" width="700 " height="500" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                        <p>Enabling protection will show a locked icon status and disabling protection will show unlocked icon. </p>
                        <center><img src={SmsProtectionLock} alt="Sms Protection Lock" width="500 " height="70" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                        <p>In order for the SMS configuration to apply to the live server, you must push the file/folder (see Pushing Files section below). Once SMS has been applied to a folder, all items within that folder will inherit those SMS settings. You may override those inherited setting for any file/folder by simply clicking the locked icon.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="pushing">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Pushing Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Pushing Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To promote a file or directory to the live server, click the <strong>push</strong> button located to the far right of your file or folder name.</p>
                        <center><img src={PushLive} alt="Push Live" width="700 " height="110" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                        <p>A confirmation window will pop up asking if you want to push the fille(s) live, click OK.</p>
                        <center><img src={PushLivePopUp} alt="Push Live PopUp" width="500 " height="150" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                        <p>Keep in mind you can promote entire directories as well as individual files. <strong>Important note:</strong> if you have created a new course directory folder for the first time you promote to the live server you must "push" the entire directory. Otherwise, if you promote a subdirectory or files inside that folder, it will not transfer to the live server.</p>

                        <p><strong>Warning! </strong>If you promote the entire course folder/directory, it will overwrite all files on the live server.</p>

                        <p>For instance, if you have a folder titled hss_arnett_lifedevelopment_3e, on the development server that contains no files, but you already have the same folder on the live server that does contains files, pushing the empty folder from the development server will overwrite and erase the files on the live server.</p>

                        <p>Once you have successfully pushed a file or folder, you will see the confirmation message in the upper right of the window. This message will also contain the live URL to the file (or folder) that you may copy to paste for use in your online courses. </p>
                        <center><img src={PushLiveURL} alt="Push Live URL" width="500 " height="100" hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                        </td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="viewing">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Viewing Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Viewing Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To view any file on the Media Server, simply click on the file name to launch a new browser window containing the file. </p>
                    <center><img src={ListOfFiles} alt="List Of Files" width="700 " height="150" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                    <p>Next, using your browser's menu options, view the address bar to see the Media Server development URL.</p>
                    <center><img src={ViewingFiles} alt="Viewing Files" width="700 " height="500" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                    <p>Change the <strong>“https://mediaconsole.pearson.com/mdc/preview/”</strong> URL to <strong>“https://media.pearsoncmg.com”</strong> to access the live URL of the file (assuming you have previously <u>pushed</u> the file).</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="renaming">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Renaming Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Renaming Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To rename a file or folder, click the <strong>rename</strong> icon located to the right of the file/folder under the rename column.</p>
                    <center><img src={RenameFile} alt="Rename File" width="700 " height="100" hspace="0" vspace="0" border="0" align="absmiddle" /></center>
                    <p>A new browser window will spawn showing the previous file/folder name in the text field. Rename your file or folder, then click the <strong>Rename</strong> button. Your main browser window will refresh, displaying the newly named file or folder.</p>
                    <center><img src={RenameFilePopUp} alt="Rename File PopUp" width="700 " height="250" hspace="0" vspace="0" border="0" align="absmiddle" /></center></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>

        <section id="deleting">
            <table border="0" width="90%" cellspacing="1" cellpadding="2" align="center" summary="Deleting Files">
                <tr align="left" valign="top">
                    <td align="left" valign="top" class="helpSubHead" colspan="2">Deleting Files</td>
                </tr>
                <tr align="left" valign="top">
                    <td align="left" valign="top" width="2%">&nbsp;</td>
                    <td align="left" valign="top" class="helpContent"><p>To permanently remove outdated or unwanted files from the Media Server, click on the check boxes to the left of the file names, then click <strong>”Delete Selected"</strong> button. </p>
                    <center><img src={DeleteFile} alt="Delete File PopUp" width="500 " height="200" hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                    <p>An alert box will confirm that you choose to delete the selected files. Clicking <strong>“OK”</strong> will permanently delete these files.</p>
                    <center><img src={DeleteFilePopUp} alt="Delete File PopUp"  width="700 " height="250"  hspace="0" vspace="0" border="0" align="absmiddle" /></center>

                    <p>Note: a folder may not be deleted if there are any files located within it. To delete a folder, you must first delete all the files inside that folder by <strong>“Select All"</strong> and then click <strong>”Delete Selected"</strong> button. Once all of the files have been deleted, click the <strong>“Back”</strong> button, and then you may delete that folder.</p></td>
                </tr>
            </table>
        </section>

        <span class="spacer"><br/><br/><br/></span>


        <span class="spacer"><br/><br/></span>
        <Footer/>
        </body>
    </>
  )
}

export default MyHelp;
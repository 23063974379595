import { put, call, takeLatest } from "redux-saga/effects";
import { ADD_SMS_INFO, SMS_CONFIG_API, DELETE_SMS_INFO,DELETE_SMS_CONFIG_API,
    UNZIP_API,UNZIP_FOLDER } from "../../../../Constants";
import apiService from "../../../utils/apiService";
import _ from 'lodash';
import { addSmsInfo } from "./actions";


function addSmsConfig(payload)
{
    return apiService.post(SMS_CONFIG_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return {
            message : {
                info: "Un authorized"
            },
            success: false
        }
    })
}

const addSmsConfigInfo = function* (payload) {
    let response;
    try {
        response = yield call(addSmsConfig,payload.payload.smsInfoPayload);
        
        return response;
    } catch (error) {

    }
}

function deleteSmsConfig(payload)
{
    return apiService.post(DELETE_SMS_CONFIG_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return {
            message : {
                info: "Un authorized"
            },
            success: false
        }
    })
}

const deleteSmsConfigInfo = function* (payload) {
    let response;
    try {
        response = yield call(deleteSmsConfig,payload.payload.deleteSmsConfigObj);
        return response;
    } catch (error) {

    }
}


function* smsConfigSaga() {
    yield takeLatest(ADD_SMS_INFO, addSmsConfigInfo);
    yield takeLatest(DELETE_SMS_INFO, deleteSmsConfigInfo);
}


export default smsConfigSaga;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../Modal/modal.css';
import apiService from "../../../utils/apiService";
import { API_URL } from '../../../utils/Constants'


const DownloadZip = () => {

    document.body.style.backgroundImage = "none";
    document.body.style.backgroundRepeat = "no-repeat";

    const [srcUrl, setSrcUrl] = useState('');
    const location = useLocation();
    let filepath = (location.pathname).replace('/mdc/download/','/');

    useEffect(async() => {
          const headers = await apiService.acquireAccessToken()
        let url = `${API_URL}/mdc/download${filepath}`;
        setSrcUrl(url);
      }, [])





    return(
        <div className="modal-content-table" > 

            <div id="preview_file" className='unzip-table'>
                <iframe src = {srcUrl} className='preview-iframe' />
            </div>
        </div>
    );

}

export default DownloadZip
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header/Header';
//import Footer from '../common/Footer/Footer';
import Banner from '../common/Banner/Banner';
import '../common/css/pw_push5.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList, getDirectoriesOptions } from '../User/actions';
import CreateUserPopup from './CreateUserPopup';
import Modal from 'react-modal';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../common/Table/Table';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
//import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
//import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import InputLabel from '@mui/material/InputLabel';
import { MS_CONSOLE_TITLE } from '../utils/Constants';


const customStyles = {
  content: {
   height: '300px'
  },
};


const UserList = ({}) => {
    const msTitle = `${MS_CONSOLE_TITLE} Environment - Media Delivery Console`;
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userDataList, setUserDataList]= useState([]);
    const [userSearchRole, setUserSearchRole] = useState('');
    const [displayUserSearch, setDisplayUserSearch] = useState('');

    const [directoryOptions, setDirectoryOptions] = useState([]);
    const [variantName, setVariantName] = useState([]);
    const ITEM_HEIGHT = 15;
    const ITEM_PADDING_TOP = 3;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 14.5 + ITEM_PADDING_TOP,
          width: 200,
          // marginBottom:'10px',
          // paddingBottom:'10px',
        },
      },
    };

    const dirOptionsList = useSelector((state) => { 
        return state.UserReducer.directoriesList
      });

    const [disableCheckbox,setDisableCheckbox] = useState(true);
    let currentPath = location.pathname;
    
    const [showPopup, setShowPopup] = useState(false) // state to control popup 

    const userList = useSelector(state => {
      return state.UserReducer.userList
    })

    const handleCreateUserClick = () => {
        setShowPopup(true)
    }

    const handleClosePopup = () => {
        setShowPopup(false)
    }

    const searchUserAccount = (e) => {
      let displayNameFilter = e.target.value
      setDisplayUserSearch(displayNameFilter)
    }

    const searchUserRole = (e) => {
      let role = e.target.value;
      setUserSearchRole(role);

    }

    useEffect(() => {

      if(dirOptionsList.length > 0)
      {
           let dirsArray = [];
           for(let i = 0; i < dirOptionsList.length; i++)
           {
               let listDirs = {};
               listDirs.value = dirOptionsList[i];
               listDirs.label = dirOptionsList[i];
               //listDirs.name = 'group';
               dirsArray.push(listDirs);
           }
           setDirectoryOptions(dirsArray)
      }
   } , [dirOptionsList]);

    const handleDirectoriesChange = (data) => {
      let selectedDirList = [];
      let filterDirLists = data.target.value;
      let allSelected = filterDirLists.filter((item) => {
          return item.value == 'All'
      });

      if(allSelected.length > 0 )
        {
            let userAccessDirsArray = [];
            for(let i = 0; i < dirOptionsList.length; i++)
            {
                userAccessDirsArray.push({value: dirOptionsList[i], label: dirOptionsList[i]})
                selectedDirList.push(dirOptionsList[i]);
            }
            setVariantName(userAccessDirsArray);
        }else{

            for(let i = 0; i < filterDirLists.length; i++)
            {
                selectedDirList.push(filterDirLists[i].value);
            }
            setVariantName(filterDirLists);
        }
  }

    useEffect(() => {

      if(!['Super Admin', 'Admin'].includes(localStorage.getItem('user.role')))
      {
        navigate('/mdc/auth/media');
        return;
      }
      dispatch(getUserList());
      dispatch(getDirectoriesOptions())
    }, [])

    useEffect(() => {
        setUserDataList([])
        let selectedDirList = [];
        if(variantName.length)
        { 
          selectedDirList = variantName.map(dir => dir.value);
        }
        const filterUsers = userList.filter((row) => {
          let splitGroups = (row.group).split(',');
            if(userSearchRole != '' && (displayUserSearch) != '' && selectedDirList.length)
            {
              return (selectedDirList.every(selectedDir => splitGroups.includes(selectedDir)) && row.displayName.toLowerCase().includes((displayUserSearch).toLowerCase()) && (row.role) == userSearchRole);  
            }else if(userSearchRole != '' && (displayUserSearch) != '')
            {
              return (row.displayName.toLowerCase().includes((displayUserSearch).toLowerCase()) && (row.role) == userSearchRole);  
            }
            else if(userSearchRole != ''  && selectedDirList.length)
            {
              return ((row.role) == userSearchRole) && (selectedDirList.every(selectedDir => splitGroups.includes(selectedDir)));  
            }else if(displayUserSearch != ''   && selectedDirList.length)
            {
              return (row.displayName.toLowerCase().includes((displayUserSearch).toLowerCase()) && (selectedDirList.every(selectedDir => splitGroups.includes(selectedDir))));
            }else if(userSearchRole != '' )
            {
              return ((row.role) == userSearchRole);  
            }else if(displayUserSearch != '')
            {
              return (row.displayName.toLowerCase().includes((displayUserSearch).toLowerCase()));  
            }
            else if(selectedDirList.every(selectedDir => splitGroups.includes(selectedDir)))
            {
              return row;
            }
        });
        if(selectedDirList != '' || userSearchRole != '' || displayUserSearch != '')
        {
          setUserDataList(filterUsers);
        }else{
          setUserDataList(userList)  
        }

    },[userList, variantName, userSearchRole, displayUserSearch])

    return (
      <div>
          <Header title={msTitle}/>
          <body>
          <Banner  title={msTitle} currentPath = {currentPath} disableCheckbox={disableCheckbox}/> 
          <br />
          <div 
            style = {{
                display : 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '5px'
            }}
          >
            <div>
                <button className='create_user_button' onClick={handleCreateUserClick}>
                    CREATE NEW USER 
                </button>
                <Modal
                 isOpen = {showPopup}
                 onRequestClose={handleClosePopup}
                 contentLabel='Create New User'
                 style={customStyles}
                 ariaHideApp={false}
                >
                  <div style={{ margin: '10px 10px' }}>
                      <CreateUserPopup onClose={handleClosePopup}/>
                  </div>
                </Modal>
            </div>
            <div style={{ display: 'flex' , alignItems: 'center', marginBottom: '10px', marginLeft: '10px' }}>
                <div style={{ marginRight: '600px'  }}>
                    <span className='userrolefilter' >User Role Filter</span>
                    <br/>
                    <select className='selectRoles'  onChange={searchUserRole}>
                        <option value=''>ALL USER ROLES</option>
                        { localStorage.getItem('user.role') == 'Super Admin' &&
                          <>
                            <option value='Super Admin'>Super Admin</option>
                            <option value='Admin'>Admin</option>
                          </>
                        }
                        <option value='Production'>Production</option>
                        <option value='Vendor'>Vendor</option>
                    </select>
                </div> 
                {showPopup === false && 
                <div style={{ display: 'flex' , alignItems: 'center', marginBottom: '20px' }}>
                {/*  */}
                <FormControl sx={{ m: 1, width: 200,  boxShadow: 'none',boxShadow: 'none' }} size="small">
                        <InputLabel style={{ fontSize:'12px', zIndex: 0}} >Filter Group</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          placeholder="Select user group"
                          id="demo-multiple-checkbox"
                          className='demo-multiple-checkbox'
                          multiple
                          variant="standard"
                          value={variantName}
                          onChange={handleDirectoriesChange}
                          renderValue={(selected) => selected.map((x) => x.label).join(', ')}
                          MenuProps={MenuProps}
                          width='200px'
                          height='37px'
                        >
                         
                          {directoryOptions.map((variant) => (
                            <MenuItem key={variant.label} value={variant}  >
                              <Checkbox 
                                checked={
                                  variantName.findIndex((item) => item.label === variant.label) >= 0
                                }
                              />
                              <ListItemText primary={variant.value} />
                            </MenuItem>
                          ))}
                        </Select>
                        </FormControl>
                    </div>
                }
                

                <SearchIcon/>   
                <input 
                    type = 'text'
                    placeholder='Search Users'
                    className='selectusers'
                    onKeyUp={searchUserAccount}
                />     
            </div>
        </div>

        <div className='generalcoursecontainer'>
          <DataTable userList={userDataList} />
        </div>
        </body>
    </div>
  )
}

export default UserList;
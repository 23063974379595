import { combineReducers } from 'redux';
import { loginReducer } from '../src/Components/Login/loginReducer';
import { directoriesReducer }  from '../src/Components/Dashboard/directoriesReducer';
import { fileUploadReducer } from '../src/Components/common/Modal/fileUploadReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { UserReducer } from '../src/Components/User/UserReducer';


const rootReducer = combineReducers({loginReducer,directoriesReducer, fileUploadReducer, UserReducer, toastr: toastrReducer});

export default rootReducer;
import React, { useState, useEffect} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from "@material-ui/core/Paper";
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { visuallyHidden } from '@mui/utils';
import '../css/pw_push5.css';
//import { Delete } from "@material-ui/icons";
import { useDispatch } from 'react-redux';
import { disableUser, deleteUserAction, getUserList, editUserInfoAction } from "../../User/actions";
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import Dialog  from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TablePagination from '@mui/material/TablePagination';
import { useNavigate, Outlet } from "react-router-dom";
import Modal from 'react-modal';
import EditUser from "../../User/EditUser";

export default function DataTable({ userList }) {

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);  
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(0);
    const [editUserPopup, setEditUserPopup] = useState(false);
    const navigate = useNavigate();
    // sorting table columns
    const [order, setOrder] = React.useState('asc') 
    const [orderBy, setOrderBy] = React.useState('displayName')

    const customStyles = {
      content: {
       height:'350px',
       top: '-10%',
       paddingLeft: '-10px'
      },
      /*overlay: {
        backgroundColor: "rgba(0, 0, 0, 0)"
      },*/
    };

    const dispatch = useDispatch()
   
    const enableDisableUser =  (peroot, status) => {
      
      dispatch(disableUser({'data' : {peroot:peroot, status:status}}))
      if(status === 'Active')
      {
        alert('User enabled succesfully')
      }
      else{
        alert('User disabled succesfully')
      }
      dispatch(getUserList());
      
    }
    const [data, setData] = useState(userList)

    useEffect (() => 
    {
      setData(userList)
      setRowsPerPage(10)
    }, [userList])

    const deleteUserModal = (peroot) => {
      setSelectedUserId(peroot);
      setDeleteDialogOpen(true);
    };

    const deleteUser = (peroot) => {
      dispatch(deleteUserAction({peroot}));
      setDeleteDialogOpen(false);
    }
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const editUser = (peroot) =>{
      dispatch(editUserInfoAction({'peroot': peroot}));
      navigate('/user/list?edituser=1');
      setEditUserPopup(true)
    }

    const closeEditUserPopup = (popupState) => {
      setEditUserPopup(!popupState);
    }

    const createSortHandler = (property) => {
      const isAsc = orderBy === property && order === 'asc' 
      setOrder(isAsc ? 'desc' : 'asc') 
      setOrderBy(property) 

      
    }

    useEffect(() =>{
      const visibleRows = 
      // React.useMemo(
      //   () =>
          stableSort(userList, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
          )
      //   [order, orderBy, page, rowsPerPage],
      // );

      setData(visibleRows);
    },[order,orderBy])
    
    function getComparator(order, orderBy) {
      return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
          return order;
        }
        return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator(a, b, orderBy) {
      if(orderBy === 'created_date' || orderBy === 'last_modified_date'|| orderBy === 'last_login_date')
      {
        //return new Date(b.values.lastLogin) - new Date(a.values.lastLogin);
        if (new Date(b[orderBy]) < new Date(a[orderBy])) {
          return -1;
        }
        if (new Date(b[orderBy]) > new Date(a[orderBy])) {
          return 1;
        }
        return 0;
      }
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  

    return (
      <div>
        <Dialog 
           open= {isDeleteDialogOpen}
           onClose={() => setDeleteDialogOpen(false)}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
        >

          <DialogTitle id="alert-dialog-box">Delete User</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">Are you sure you want to delete this user?</DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">Cancel</Button>
            <Button 
             onClick={() => {
              deleteUser(selectedUserId);
             }}
             color="primary"
             autoFocus
            >
            Yes
            </Button>
          </DialogActions>

        </Dialog>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" className="datatable">
          <TableHead>
            <TableRow>
              <TableCell>
                Actions
              </TableCell>
              <TableCell
                key='displayName'
                sortDirection={orderBy === 'displayName' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'displayName'}
                  direction={orderBy === 'displayName' ? order : 'asc'}
                  onClick={() => { createSortHandler('displayName')}}
                >
                &nbsp;Name
                {orderBy === 'displayName' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell
                key='mail'
                sortDirection={orderBy === 'mail' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'mail'}
                  direction={orderBy === 'mail' ? order : 'asc'}
                  onClick={() => { createSortHandler('mail')}}
                >
                Email
                {orderBy === 'mail' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                </TableCell>
              <TableCell
                key='group'
                sortDirection={orderBy === 'group' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'group'}
                  direction={orderBy === 'group' ? order : 'asc'}
                  onClick={() => { createSortHandler('group')}}
                >
                Group
                {orderBy === 'group' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                </TableCell>
              <TableCell
                key='role'
                sortDirection={orderBy === 'role' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'role'}
                  direction={orderBy === 'role' ? order : 'asc'}
                  onClick={() => { createSortHandler('role')}}
                >
                  Role
                  {orderBy === 'role' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                
                </TableCell>
              <TableCell
                key='created_date'
                sortDirection={orderBy === 'created_date' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'created_date'}
                  direction={orderBy === 'created_date' ? order : 'asc'}
                  onClick={() => { createSortHandler('created_date')}}
                >
                &nbsp;Created
                {orderBy === 'created_date' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                  </TableSortLabel>
                </TableCell>
              <TableCell
                key='last_modified_date'
                sortDirection={orderBy === 'last_modified_date' ? order : false}
              >
                 <TableSortLabel
                  active={orderBy === 'last_modified_date'}
                  direction={orderBy === 'last_modified_date' ? order : 'asc'}
                  onClick={() => { createSortHandler('last_modified_date')}}
                >
                &nbsp;Modified
                {orderBy === 'last_modified_date' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
                </TableCell>
                <TableCell
                key='last_login_date'
                sortDirection={orderBy === 'last_login_date' ? order : false}
              >
                 <TableSortLabel
                  active={orderBy === 'last_login_date'}
                  direction={orderBy === 'last_login_date' ? order : 'asc'}
                  onClick={() => { createSortHandler('last_login_date')}}
                >
                &nbsp;Last login
                {orderBy === 'last_login_date' ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data?.length) ?  
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                <TableRow key={user.peroot}>
                  {(!user.loggedInUser) ?
                  <TableCell>
                    {(user.status === 'Active') ?
                      <PersonRemoveAlt1OutlinedIcon title='Disable User' className="folderpointer" onClick={() => enableDisableUser(user.peroot, 'Inactive')} />
                      :
                      <PersonAddAlt1OutlinedIcon title='Enable User' className="folderpointer" onClick={() => enableDisableUser(user.peroot, 'Active')} />
                    }
                    <EditOutlinedIcon title='Edit User' className="folderpointer" onClick={() => editUser(user.peroot)}/>
                    </TableCell> :
                    <TableCell></TableCell> 
                    }
                    <TableCell>{user.displayName}</TableCell>
                    <TableCell>{user.mail}</TableCell>
                    <TableCell>{user.group}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{user.created_date}</TableCell>
                    <TableCell>{user.last_modified_date}</TableCell>
                    <TableCell>{user?.last_login_date}</TableCell>
                    <TableCell><DeleteOutlineOutlinedIcon onClick={() => deleteUserModal(user.peroot)} /></TableCell>
                  </TableRow>
                  )): 
                  <TableRow>
                    No data found
                  </TableRow>
            }
          </TableBody>
        </Table>
        </TableContainer>
      </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50]}
          colSpan={3}
          count={userList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ float: 'right', margin: '16px' }}
          SelectProps={{
            inputProps: {
               'aria-label': 'rows per page',
            },
            native: true,
          }}
        />
        { editUserPopup && 
              <Modal
              isOpen = {editUserPopup}
              onRequestClose={closeEditUserPopup}
              contentLabel="Edit User"
              ariaHideApp={false}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              >
                  <EditUser  
                    isOpen = {editUserPopup}
                    onRequestClose={closeEditUserPopup}
                  />
                  <Outlet />
              </Modal>
          }   
      </div>
    );
   }
import React, { useState, useEffect }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from "react-select";
import { searchUserInAD, addADUser, getDirectoriesOptions, getUserList } from './actions';


const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        //borderBottom: '1px dotted pink',
        //color: state.isSelected ? 'red' : 'blue',
        padding: 5,
    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 600,
        fontSize: 10,
        borderBottom: 'groove'
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

const CreateUserPopup = ({ onClose }) => {

    const dispatch = useDispatch();
    const [roleOptions, setRoleOptions] = useState(['Super Admin', 'Admin' , 'Production', 'Vendor']);
    const [formFields, setFormFileds] = useState({
        peroot : '',
        department : '',
        manager : '',
        manager_department : '',
        group : '',
        role : ''
    });
    const [selectedOptions, setSelectedOptions] = useState('');
    const [optionList, setOptionList] = useState([]);
    const [directoryOptions, setDirectoryOptions] = useState([]);
    const [selectedDirs, setSelectedDirs] = useState([]);
    const userSuggestionsList = useSelector(state  => { return state.UserReducer.userSuggestionList });
    const dirOptionsList = useSelector((state) => { 
        return state.UserReducer.directoriesList
      });
    
    const resetSearch = () => {
        setFormFileds({
            peroot : '',
            department : '',
            manager : '',
            manager_department : '',
            group : '',
            role : ''
        });
        setSelectedOptions('');
        setOptionList([]);
        setSelectedDirs([]);

        dispatch(searchUserInAD({ userPeroot:'' }));
    }

    const fetchUser = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let userPeroot = e.target.value;

        if(userPeroot.length > 3)
        {
            //userPeroot = `${userPeroot}@pedev.com`;
            dispatch(searchUserInAD({userPeroot}));
            //setUserEmailPeroot(userPeroot);
        }
        else if(userPeroot.length == 0){
            resetSearch();
        }
    }

    useEffect(() => {
        if(userSuggestionsList?.length > 0)
        {
            let userArray = [];
            for(let i = 0; i < userSuggestionsList.length; i++)
            {
                let userOptions = {};
                userOptions.value = [(userSuggestionsList[i]?.onPremisesSamAccountName)?.toLowerCase(), userSuggestionsList[i].mail];
                userOptions.label = userSuggestionsList[i]?.displayName;
                userOptions.manager = userSuggestionsList[i]?.manager?.displayName;
                userOptions.manager_department = userSuggestionsList[i]?.manager?.department;
                userOptions.department = userSuggestionsList[i]?.department;
                userArray.push(userOptions);
            }
            resetSearch();
            setOptionList(userArray);
        }
    }, [userSuggestionsList]);

    function handleSelect(data) {
        setFormFileds({ ...formFields,
            peroot : data.value[0], 
            department : data.department, 
            manager : data.manager, 
            manager_department : data.manager_department
        })
        setSelectedOptions(data);
    }

    useEffect(() => {
        if(localStorage.getItem('user.role') == 'Admin')
        {
            setRoleOptions(['Production', 'Vendor'])
        }
        dispatch(getDirectoriesOptions());
    }, []);

    
    useEffect(() => {

       if(dirOptionsList.length > 0)
       {
            let dirsArray = [];
            dirsArray.push({ value: 'All', label: 'All'});
            for(let i = 0; i < dirOptionsList.length; i++)
            {
                let listDirs = {};
                listDirs.value = dirOptionsList[i];
                listDirs.label = dirOptionsList[i];
                //listDirs.name = 'group';
                dirsArray.push(listDirs);
            }
            setDirectoryOptions(dirsArray)
       }
    } , [dirOptionsList]);

    const handleChange = (data) => {
        let selectedDirList = [];
        let allSelected = data.filter((item) => {
            return item.value == 'All'
        });

        if(allSelected.length > 0 )
        {
            let userAccessDirsArray = [];
            for(let i = 0; i < dirOptionsList.length; i++)
            {
                userAccessDirsArray.push({value: dirOptionsList[i], label: dirOptionsList[i]})
                selectedDirList.push(dirOptionsList[i]);
            }
            setSelectedDirs(userAccessDirsArray);
        }else{

            for(let i = 0; i < data.length; i++)
            {
                selectedDirList.push(data[i].value);
            }
            setSelectedDirs(data);
        }
        selectedDirList = selectedDirList.toString();
        setFormFileds({...formFields,  'group' : selectedDirList })
    }

    const handleRoleChange = (e) =>{
        e.preventDefault();
        setFormFileds({...formFields,  [e.target.name] : e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(formFields.group == '')
        {
            alert('Please Select Groups');
            return
        }
        // add active directory user
        dispatch(addADUser({data : formFields }));
        onClose();
        dispatch(getUserList());
    }

    return(
        <div >     
                          
            <div >
                <h4 className='createusermodalheading' >Add New User(s)</h4>
                <form onSubmit={handleSubmit}>
                    <div onKeyUp={fetchUser}>
                        <label htmlFor='userIdorEmail' className='labelFontStyle'>Search by User ID or Email*</label>
                        {/* <input type="text" id="userIdorEmail" required 
                        className='emailfield' onKeyUp={fetchUser}
                        /> */}
                        
                        <ReactSelect styles={selectStyles}
                            options={optionList}
                            placeholder="Search by User ID or Email*"
                            value={selectedOptions}
                            onChange={handleSelect}
                            isSearchable={true}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            ignoreAccents={false}
                            ignoreCase={false}
                        />
                        
                    </div>
                    <br/>

                    <div>
                        <ReactSelect 
                            styles={selectStyles}
                            options={directoryOptions}
                            placeholder="Search by Folder Name*"
                            isSearchable={true}
                            //className='labelFontStyle'
                            maxMenuHeight={100}
                            onChange={handleChange}
                            isMulti
                            value={selectedDirs}
                            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                            //ignoreAccents={false}
                            //ignoreCase={false}
                        />
                    </div>
                    <br/>

                    <div>
                        <select id="userRole" required className='userselectdropdown labelFontStyle' name="role" onChange={handleRoleChange}>
                            <option value='' >Select User Role*</option>
                            {
                                roleOptions.map((role) => (
                                <option key={role} value={role} selected = { role === formFields.role ? 'selected' : ''}>
                                    {role}
                                </option>
                                )
                                )
                            }
                        </select>
                    </div>
                    <br/>

                    <div style={{ float: 'right', bottom: '10px', right: '10px' }}>
                        <button onClick={onClose} className = "usercreatebutton usercancel"
                        >CANCEL</button>
                        <button className = "usercreatebutton useradd" type="submit"
                        >ADD</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default CreateUserPopup;
const prod ={
    NAME: 'PROD',
    API_URL: 'https://api.mediaconsole.pearson.com/api',
    API_BASE_URL:'https://api.mediaconsole.pearson.com',
    SOCKET_URL:'wss://socket.mediaconsole.pearson.com/',
    CLIENT_ID: "697b5223-f6ab-40c1-9441-507a2e9d1684",
    AUTHORITY: "8cc434d7-97d0-47d3-b5c5-14fe0e33e34b",
    API_SCOPES: "67054fca-73dc-4535-a18d-0e40cd9315d5"
}

export default prod;

import { API_BASE_URL } from "../Constants";

const qa ={
    NAME: 'QA',
    API_BASE_URL:'https://api.mediaconsole-qa.pearson.com',
    API_URL: 'https://api.mediaconsole-qa.pearson.com/api',
    SOCKET_URL:'wss://socket.mediaconsole-qa.pearson.com/',
    CLIENT_ID: "9f6d73c2-26f2-4ffe-b75f-59922670ba94",
    AUTHORITY: "209a71f6-c9bb-48f1-a23d-a26d275b75e2",
    API_SCOPES: "88c25c23-3e9f-4607-9f1a-686d8ef0bf65"
}

export default qa;

import { put, call, takeLatest } from "redux-saga/effects";
import { LOGIN_REQUEST, LOGIN_API, PUT_LOGIN_REQUEST } from "../../Constants";
import apiService from "../utils/apiService";
import _ from 'lodash';

function login(payload)
{
    return apiService.post(LOGIN_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return {
            message : {
                info: "Un authorized"
            },
            success: false
        }
    })
}

const getLoginRequest = function* (loginCredentials) {
    let response;
    try {
        response = yield call(login,loginCredentials.payload);
        yield put({ type : PUT_LOGIN_REQUEST, response});

        return response;
    } catch (error) {

    }
}

function* loginSaga() {
    yield takeLatest(LOGIN_REQUEST, getLoginRequest);
}


export default loginSaga;
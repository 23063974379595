import { put, call, takeLatest } from "redux-saga/effects";
//import { push } from 'react-router-redux';
import { GET_SUB_DIRECTORIES, DIRECTORY_PATH, PUSH_MEDIA_FILE_ACTION, PUSH_FUNC_API, PUT_SUCCESS_LIVE_URLS,
    GET_FILE_SMS_INFO, SMS_FILE_API, PUT_SMS_FILE_INFO, DELETE_SELECTED_FILES_LIST, DELETE_FILE_API,
    BULK_DOWNLOAD_FILES, BULKDOWNLOAD_FILE_API, USER_DISPLAY_MSG, CLOSE_BULK_DOWNLOAD_POPUP } from "../../Constants";
import apiService from "../utils/apiService";
import _ from 'lodash';
import { updateSubDirectories, noDirOrUserFound, deleteFileStateInfo } from "./actions"
import { toastr } from "react-redux-toastr";
import { MS_URL } from '../utils/Constants'

function getDirectories(payload)
{
    return apiService.post(DIRECTORY_PATH,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const getDirectoriesList = function* (payload) {
    let response;
    try {
        response = yield call(getDirectories,payload.payload);
        if(_.get(response,"status_code",'') === 404)
        {
            yield put(noDirOrUserFound(response));
        }
        yield put(updateSubDirectories(response));
        return response;
    } catch (error) {

    }
}

function pushMediaFileToLive(payload)
{
    return apiService.post(PUSH_FUNC_API,payload.payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return {
            message : {
                info: "Push functionality API error"
            },
            success: false
        }
    })
}

const pushMediaFile = function* (payload) {
    let response;
    try {
        response = yield call(pushMediaFileToLive,payload.payload);
        if(_.get(response,"status_code",'') === 200)
        {
            yield put({ type : PUT_SUCCESS_LIVE_URLS, response});
        }
        if(_.get(response,"status_code",'') === 404)
        {
            toastr.error(response.error_message);
            return;
        }
        return response;
    } catch (error) {

    }
}

function getSMSFileApiInfo(payload)
{
    return apiService.post(SMS_FILE_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}


const getSMSFileInfo = function* (payload) {
    let response;
    try {
        response = yield call(getSMSFileApiInfo,payload.payload);
        if(_.get(response,"status_code",'') === 200)
        {
            yield put({ type : PUT_SMS_FILE_INFO, response});
        }
        if(_.get(response,"status_code",'') === 404)
        {
            return;
        }
        return response;
    } catch (error) {

    }
}


function deleteSelectedFiles(payload)
{
    return apiService.post(DELETE_FILE_API,payload).then((response) => {
        return _.get(response,"data",{});
    }).catch(error => {
        return error.response.data;
    })
}

const deleteSelectedFilesFromDir = function* (payload) {
    let response;
    try {
        response = yield call(deleteSelectedFiles,payload.payload);
        if(_.get(response,"status_code",'') === 200)
        {
            yield put(deleteFileStateInfo(response));
        }
        if(_.get(response,"status_code",'') === 400 || _.get(response,"status_code",'') === 500)
        {
            yield put(deleteFileStateInfo(response));
        }
    } catch (error) {

    }
}

function downloadBulkFiles(payload)
{
    return apiService.post(BULKDOWNLOAD_FILE_API,payload.encodedPaths)
    .then(response => {
        let downloadFileName = response.data.message
        if (response.data.status_code == 200)
        {
            setTimeout(() => {
                const url = `${MS_URL}mdc/download/${downloadFileName}`
                window.open(url, '_blank');
            },10000)
            return _.get(response,"data",{});
        }
        else{
            return _.get(response,"data",{});
        }
    })
    .catch(error => {
        console.log(error);
    });
}

const downloadFilesInBulk = function* (payload) {
    let response;
    try {
        response = yield call(downloadBulkFiles,payload.payload);
        if(_.get(response,"status_code",'') === 200)
        {
            yield put({ type : CLOSE_BULK_DOWNLOAD_POPUP, response});
            response.message = 'Please wait for a couple of seconds. Your selected files are downloading. If the download is not happening, please allow browser pop-ups for the site';
            yield put({ type : USER_DISPLAY_MSG, response});
        }
        if(_.get(response,"status_code",'') === 422)
        {
            yield put({ type : USER_DISPLAY_MSG, response});
        }
    } catch (error) {

    }
}



function* getDirectoriesSaga() {
    yield takeLatest(GET_SUB_DIRECTORIES, getDirectoriesList);
    yield takeLatest(PUSH_MEDIA_FILE_ACTION, pushMediaFile);
    yield takeLatest(GET_FILE_SMS_INFO, getSMSFileInfo);
    yield takeLatest(DELETE_SELECTED_FILES_LIST, deleteSelectedFilesFromDir);
    yield takeLatest(BULK_DOWNLOAD_FILES, downloadFilesInBulk);
}


export default getDirectoriesSaga;